<template>
  <div>
    <fp-select
      :items="activeModules"
      :placeholder="
        $t('healthProfessional.patientList.diagramsTab.selectChart')
      "
      textKey="label"
      valueKey="key"
      v-model="moduleName"
    />
    <health-goal
      v-if="moduleName"
      :UserId="participant.UserId"
      :ModuleName="moduleName"
    />
  </div>
</template>
<script>
import HealthGoal from "../../HealthModule/HealthGoal.vue";
export default {
  name: "ParticipantList_HealthGoal",
  components: { HealthGoal },
  props: {
    participant: Object,
    project: Object,
  },
  data() {
    return {
      moduleNameList: [
        {
          key: "Exercise",
          label: this.$t("module.modules.exerciseModule"),
        },
        {
          key: "Cardiology",
          label: this.$t("module.modules.cardiologyModule"),
        },
        {
          key: "BodyWeight",
          label: this.$t("module.modules.bodyWeightModule"),
        },
        {
          key: "Pulmonology",
          label: this.$t("module.modules.pulmonologyModule"),
        },
        {
          key: "Depression",
          label: this.$t("module.modules.depressionModule"),
        },
        {
          key: "Nutrition",
          label: this.$t("module.modules.nutritionModule"),
        },
      ],
      moduleName: null,
    };
  },
  computed: {
    activeModules() {
      if (
        !this.project ||
        !this.project.EnabledModules ||
        !this.project.EnabledModules.HealthProfessional
      ) {
        return this.moduleNameList;
      }
      return this.moduleNameList.filter((x) =>
        this.project.EnabledModules.HealthProfessional.includes(x.key)
      );
    },
  },
};
</script>